/* ================================= */
/* === WORKSHOME =================== */
/* ================================= */

.bloc-content-works {
	background-color: var(--color_white);
	overflow-y: scroll;
	transition: background-color var(--time_1);
}

.bloc-content-works-list {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	width: 100%;
}

.bloc-content-works-list .items {
	list-style: none;
	border-top: var(--border_size) var(--border_type) var(--border_color);
	height: 120px;
	width: 100%;
	text-align: center;
	transition: border-color var(--time_3);
}

.bloc-content-works-list .items:first-child {
	border-top: 0;
}

.bloc-content-works-list .items:hover,
.bloc-content-works-list .items a.is-active  {
	background-color: var(--color_black);
	cursor: pointer;
}

.bloc-content-works-list .items a {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.bloc-content-works-list .items:hover a,
.bloc-content-works-list .items a.is-active {
	color: var(--color_white);
}

.bloc-content-works-list .items:hover svg .fill {
	fill: var(--color_white);
}