/* ================================= */
/* === RESUME ====================== */
/* ================================= */

body.resume .content {
	grid-template-columns: 100%;
	overflow-y: scroll;
}

body.resume .title-information-player-attachment {
	display: flex;
	flex-direction: column;
	height: calc(100% - 64.6px);
}

body.resume .player-attachment {
	border-top: var(--border_size) var(--border_type) var(--border_color);
	display: flex;
	height: 64.6px;
}

body.resume .player ,
body.resume .attachment {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 100%;
}

body.resume .player {
	border-right: var(--border_size) var(--border_type) var(--border_color);
}

body.resume .player div,
body.resume .player div .svg {
	width: 34.5px;
	height: 15.61px;
	cursor: pointer;
}

body.resume .attachment a,
body.resume .attachment .svg  {
	width: 34.5px;
	height: 22.99px;
	cursor: pointer;
}

/* Permet de supprimer les espaces entre les div (images) dans la zone de droite */
body.resume .bloc-content:nth-child(2) div {
	display: flex;
	flex-direction: column;
}

@media only screen and (min-width: 768px) {
	body.resume .content {
		grid-template-columns: 1fr 2fr;
	}
	
	body.resume .bloc-content:first-child {
		overflow: hidden;
	}
	
	body.resume .bloc-content:nth-child(2) {
		border-left: var(--border_size) var(--border_type) var(--border_color);
	}
	
	body.resume .title-information {
		height: 100%;
		overflow-y: scroll;
	}
	
	body.resume .player ,
	body.resume .attachment {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50%;
		height: 100%;
	}
}