/* ================================= */
/* === VARIABLES =================== */
/* ================================= */

:root {
	--font_family: 'Roboto', sans-serif;
	--font_weight: 700;
	--font_style: normal;
	--font_size: 13px;
	--font_lineHeight: 1.2rem;
	--font_letterSpacing: .6px;
	--font_textTransform: none;
	
	--color_white: #FFFFFF;
	--color_black: #000000;
	--color_yellow: #FFF000;
	--color_darkYellow: #A59000;
	
	--border_size: 1px;
	--border_type: solid;
	--border_color: var(--color_black);
	
	--time_1: 1s; /* background */
	--time_2: 1s; /* border / fill */
	--time_3: 3s; /* color */
	
	--whiteToBlack: var(--color_black);
	--blackToWhite: var(--color_white);
}

/* ================================= */
/* === UNIVERSAL =================== */
/* ================================= */

*,
*:before, 
*:after {
	box-sizing: border-box; 
}

/* ================================= */
/* === OL, UL ====================== */
/* ================================= */

ol, ul {
	margin: 1em 0;
	padding: 0;
}

/* ================================= */
/* === LI ========================== */
/* ================================= */

ol li,
ul li {
	list-style: none;
}

/* ================================= */
/* === A =========================== */
/* ================================= */

a,
a:visited {
	text-decoration: none;
	color: var(--color_black);
	/*transition: color var(--time_3);*/
}

a:hover, 
a:focus, 
a:active {
	text-decoration: none;
	color: var(--color_black);
}

a:hover {
	text-decoration: underline var(--color_black);
}

a svg,
a img {
	display: block;
}

/* ================================= */
/* === STRONG  ===================== */
/* ================================= */

b, strong {
	font-weight: 700;
}

/* ================================= */
/* === BUTTON  ===================== */
/* ================================= */

button {
	text-transform: var(--font_textTransform);
}

/* ================================= */
/* === IMG ========================= */
/* ================================= */

img {
	width: 100%;
	height: auto;
}

/* ================================= */
/* === HTML ======================== */
/* ================================= */

html {
	margin: 0;
	padding: 0;
	font-family: var(--font_family);
	font-weight: 300;
	font-style: var(--font_style);
	font-size: var(--font_size);
	line-height: var(--font_lineHeight);
	letter-spacing: var(--font_letterSpacing);
	text-transform: var(--font_textTransform);
}

/* ================================= */
/* === BODY ======================== */
/* ================================= */

body {
	margin: 3px;
	padding: 0;
	background-color: var(--color_white);
	color: var(--color_black);
	text-transform: var(--font_textTransform);
	transition: background-color var(--time_1), color var(--time_3);
}

/* ================================= */
/* === ERRORENLARGESITE ============ */
/* ================================= */

.errorEnlargeSite {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	z-index: 99;
	background-color: var(--color_white);
	display: none;
	visibility: hidden;
	color: var(--color_black)
}

@media only screen and (max-width: 300px), screen and (max-height: 400px) {
	.errorEnlargeSite {
		display: flex;
		visibility: visible;
	}	
}

.errorEnlargeSite .logo {
	width: 8px;
	height: 11.5px;
	margin-top: 8px;
}

.errorEnlargeSite .message-eggplant {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100px;
	position: relative;
	margin: 8px 0;
}

.errorEnlargeSite .message {
	background-color: var(--color_white);
	font-weight: 700;
	border: var(--border_size) var(--border_type) var(--border_color);
	padding: 0 8px 2px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 190px;
}

@media only screen and (max-width: 240px) {
	.errorEnlargeSite .message {
		width: 100px;
		font-size: .9rem;
		padding-bottom: unset;
	}
}

.errorEnlargeSite .eggplant {
	height: 100%;
	width: 100%;
	min-height: 50px;
	margin-bottom: 8px;
}

.errorEnlargeSite .eggplant svg {
	height: 100%;
	width: 100%;
}

.errorEnlargeSite .solution {
	font-size: .6rem;
}

/* ================================= */
/* === ERRORROTATESITE ============= */
/* ================================= */

.errorRotateSite {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	z-index: 99;
	background-color: var(--color_white);
}

.errorRotateSite .logo {
	width: 30px;
	height: 43px;
	margin-top: 8px;
}

.errorRotateSite .message {
	background-color: var(--color_white);
	font-weight: 700;
	border: var(--border_size) var(--border_type) var(--border_color);
	padding: 0 8px 2px;
	white-space: nowrap;
	margin: 32px 0;
}

.errorRotateSite .smiley {
	width: 75px;
	height: 75px;
	margin-bottom: 8px;
}

/* ================================= */
/* === APP ========================= */
/* ================================= */

.app {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: var(--border_size) var(--border_type) var(--border_color);
	height: 100%;
	position: relative;
	transition: border var(--time_2);
}

/* ================================= */
/* === CONTAINER =================== */
/* ================================= */

.container {
	display: flex;
	border: var(--border_size) var(--border_type) var(--border_color);
	border-right: none;
	border-left: none;
	position: relative;
	transition: border var(--time_2);
	overflow-y: hidden;
	height: 100%;
}

@media only screen and (max-width: 300px), screen and (max-height: 400px) {
	.container {
		display: none!important;
		visibility: hidden!important;
	}
}

@media only screen and (min-width: 768px) {
	.container {
		min-height: 100px;
		max-height: 800px;
		flex-basis: 400px;
		flex-grow: 2;
		flex-shrink: 2;
	}
	
	body.home .container {
		border-color: var(--color_black);
	}
	
}

/* ================================= */
/* === CONTENT ===================== */
/* ================================= */

/* QUAND ON EST SUR MOBILE, AVEC LE PORTFOLIO D'OUVERT (HOME - TAP FOR PLEASURE) ON SUPPRIME LES BARRES DE SCROLL */
.content {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

body.contact .content {
	overflow-y: scroll;
}

@media only screen and (min-width: 768px) {
	.content {
		display: grid;
		/*overflow-y: scroll;*/
	}
}

@media only screen and (min-width: 768px) {
	body.contact .content {
		overflow-y: hidden !important;
	}
}

/* ================================= */
/* === BLOC-CROSS ================== */
/* ================================= */

.bloc-worksMobile, 
.bloc-works,
.bloc-shop,
.bloc-more {
	height: 100%;
	position: relative;
}

.bloc-worksMobile {
	visibility: visible;
	display: block;
}

body.shop .bloc-worksMobile {
	visibility: hidden;
	display: none;
}

body.shop .bloc-shop {
	visibility: visible;
	display: block;
}

.bloc-works,
.bloc-shop,
.bloc-more {
	visibility: hidden;
	display: none;
}

@media only screen and (min-width: 768px) {
	.bloc-worksMobile {
		visibility: hidden;
		display: none;
	}
	
	.bloc-works {
		min-height: 100px;
		max-height: 800px;
		flex-basis: 400px;
		flex-grow: 2;
		flex-shrink: 2;
		box-sizing: border-box;
	}
	
	.bloc-works,
	.bloc-shop,
	.bloc-more {
		visibility: visible;
		display: block;
	}
}

.bloc-content-worksMobile,
.bloc-content-works,
.bloc-content-shop,
.bloc-content-more {
	z-index: 2;
    position: absolute;
    top: 0;
	width: 100%;
	height: 100%;
}

.bloc-content-worksMobile {
	visibility: visible;
	display: block;
}

.bloc-content-works,
.bloc-content-shop,
.bloc-content-more {
	visibility: hidden;
	display: none;
}

body.shop .bloc-content-shop {
	visibility: visible;
	display: block;
}

@media only screen and (min-width: 768px) {
	.bloc-content-worksMobile {
		visibility: hidden;
		display: none;
	}
	
	.bloc-content-works,
	.bloc-content-shop,
	.bloc-content-more {
		visibility: visible;
		display: block;
	}
}

.bloc-content-works-list .close,
.bloc-content-shop-list .close,
.bloc-content-more-list .close {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bloc-content-works-list .close svg,
.bloc-content-shop-list .close svg,
.bloc-content-more-list .close svg {
	width: 16px;
	height: 16px;
	cursor: pointer;
	transform: rotate(-45deg);
}

.bloc-content-works-list .close svg .fill,
.bloc-content-shop-list .close svg .fill,
.bloc-content-more-list .close svg .fill {
	fill: var(--color_black);
	/*transition: fill var(--time_3);*/
}

.bloc-cross {
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

	/* === CROSS ======================= */

	.bloc-cross .cross {
		height: 100%;
		width: 100%;
		position: absolute;
		display: block;
	}

	.bloc-cross .cross svg {
		object-fit: cover;
		height: 100%;
		width: 100%;
		display: block;
	}

	.bloc-cross .cross svg .stroke {
		stroke: var(--color_black);
		stroke-width: .4;
		stroke-linecap: square;
		fill: none;
		fill-rule: evenodd;
		transition: stroke var(--time_1);
	}
	
	.bloc-cross .title {
		font-weight: 700;
		border: var(--border_size) var(--border_type) var(--border_color);
		padding: 2px 80px;
		z-index: 1;
		cursor: pointer;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--color_white);
		transition: background-color var(--time_1);
		text-transform: uppercase;
	}

	/* body.home .bloc-cross .title,
	body.portfolioMobile .bloc-cross .title {
		background-color: var(--color_white) !important;
	}

	body.studio .bloc-cross .title {
		background-color: var(--color_black) !important;
	}
	
	body.contact .bloc-cross .title {
		background-color: var(--color_yellow) !important;
	} */
	
	@media only screen and (min-width: 768px) {
		.bloc-cross .title:hover {
			color: #FFFFFF;
			background: #000000;
		}
		
		.bloc-cross .cross svg .stroke {
			stroke-width: .4;
		}
	}

/* ================================= */
/* === BLOC-CONTENT ================ */
/* ================================= */

.bloc-content {
	position: relative;
	/*transition: border var(--time_1);*/
}

@media only screen and (min-width: 768px) {
	.bloc-content {
		height: 100%;
		overflow-y: scroll;
	}
}

	/* === TITLE ======================= */

	.bloc-content .title {
		padding: 24px 16px;
		border-bottom: var(--border_size) var(--border_type) var(--border_color);
		font-weight: 700;
		transition: border var(--time_1);
	}

	.bloc-content:nth-child(2) .title,
	.bloc-content:nth-child(3) .title,
	.bloc-content:nth-child(4) .title  {
		border-top: var(--border_size) var(--border_type) var(--border_color);
	}

	@media only screen and (min-width: 768px) {
		.bloc-content .title {
			border-top: none!important;
		}
	}

	/* === INFORMATION ================= */

	.bloc-content .information {
		padding: 0 16px;
	}