/* ================================= */
/* === HOME ======================== */
/* ================================= */

body.home .content,
body.shop .content {
	/*grid-template-columns: repeat(3, 1fr);*/
	grid-template-columns: 1fr;
}

@media only screen and (min-width: 768px) {
	body.home .content {
		grid-template-columns: 1fr;
	}
	
	body.shop .content {
		grid-template-columns: 1fr;
	}
	
	/*
	body.home .bloc-content .title  {
		border-color: var(--color_black);
	}
	*/
	
	/*
	body.home .bloc-shop,
	body.home .bloc-more,
	*/
	
	body.shop .bloc-shop,
	body.shop .bloc-more {
		border-left: var(--border_size) var(--border_type) var(--border_color);
		border-right: var(--border_size) var(--border_type) var(--border_color);
		transition: border-color var(--time_1);
	}
}