/* ================================= */
/* === STUDIO ====================== */
/* ================================= */

body.studio a,
body.studio a:visited {
	color: var(--color_white);
}

body.studio .content a,
body.studio .content a:visited {
	color: var(--color_yellow);
}

body.studio {
	background-color: var(--color_black);
	color: var(--color_white);
}

body.studio .app {
	border-color: var(--color_white);
}

body.studio .container {
	border-color: var(--color_white);
}

body.studio .bloc-content .title {
	border-color: var(--color_white);
}

body.studio .background {
	position:absolute;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color_black);
	border-left: var(--border_size) var(--border_type) var(--color_white);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	cursor: pointer;
}

body.studio .background-content {
	width: 75%;
	max-width: 400px;
}

body.studio .content {
	grid-template-columns: 25% 25% 50%;
	grid-template-columns: 100%;
	position: absolute;
}

body.studio .bloc-cross .title {
	background-color: pink;
	border-color: var(--color_white);
}

body.studio .bloc-cross .cross svg .stroke {
	stroke: var(--color_white);
}

body.studio .bloc-shop,
body.studio .bloc-more {
	border-color: var(--color_white);
}


@media only screen and (min-width: 768px) {
	body.studio .background {
		width: 50%;
	}
	
	body.studio .content {
		grid-template-columns: 50% 16.66% 16.66% 16.66%;
	}
	
	body.studio .bloc-content:nth-child(2),
	body.studio .bloc-content:nth-child(3),
	body.studio .bloc-content:nth-child(4) {
		border-left: var(--border_size) var(--border_type) var(--color_white);
		transition: border-color var(--time_1);
	}
}