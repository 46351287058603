/* ================================= */
/* === WORKS ======================= */
/* ================================= */

body.portfolio .content {
	grid-template-columns: 1fr;
}

.bloc-content-medias {
	background-color: var(--color_black);
	min-height: 500px;
}

.bloc-content-medias div {
	display: flex;
    flex-direction: column;
}

.bloc-content-informations {
	background-color: var(--color_yellow);
	min-height: 100px;
	display: flex;
	justify-content: space-between;
	padding: 32px;
}

.bloc-content-informations div {
	width: 33.33%;
}

.vimeo {
    padding: 56.25% 0 0 0;
    position: relative;
}

.vimeo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	border: 0;
	margin: 0;
	padding: 0;
}

@media only screen and (min-width: 768px) {
	body.portfolio .content {
		grid-template-columns: 1fr 2fr;
	}
	
	/* BUG QUAND ON ÉTAIT SUR UN PROJET, LA LISTE DE TOUS LES PROJETS (À GAUCHE) AFFICHAIT UNE DOUBLE SCROLLBAR */ 
	body.portfolio .bloc-content:first-child {
		overflow: hidden;
	}
	
	body.portfolio .bloc-content:nth-child(2) {
		border-left: var(--border_size) var(--border_type) var(--border_color);
	}
}