/* ================================= */
/* === WORKSHOME =================== */
/* ================================= */

.bloc-content-newPresentationWorks {
	/*background-color: var(--color_white);*/
	overflow-y: scroll;
	transition: background-color var(--time_1);
	height: 100%;
}

.bloc-content-newPresentationWorks-list {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
	width: 100%;
	flex-wrap: wrap;
	height: 100%;
}

.bloc-content-newPresentationWorks-list .items {
	/* Mettre 3 : SI 12 PROJETS, 3 LIGNES DE 4 PROJETS (= 12 PROJETS) */
	/* Mettre 4 : SI 16 PROJETS, 4 LIGNES DE 4 PROJETS (= 16 PROJETS) */
	height: calc(50% + (3 * 2px));
	/*height: calc(50%);*/
	width: calc(100% / 4);
	text-align: center;
	transition: border-color var(--time_3);
	border-top: 1px solid var(--color_black);
	border-right: 1px solid var(--color_black);;
}

/* On sélectionne les 4 premiers éléments, pour supprimer la bordure du haut */
.bloc-content-newPresentationWorks-list .items:nth-child(-n+4) {
	transition: border-color var(--time_3);
	border-top: none;
}

/* On sélectionne tous les 4 éléments, pour supprimer la bordure de droite */
.bloc-content-newPresentationWorks-list .items:nth-child(4n) {
	transition: border-color var(--time_3);
	border-right: none;
}

/* On sélectionne les 4 derniers éléments, pour ajouter une bordure en bas */
.bloc-content-newPresentationWorks-list .items:nth-last-child(-n+4) {
	transition: border-color var(--time_3);
	/*border-bottom: 1px solid var(--color_black);*/
}

/*.bloc-content-works-list .items:hover {
	background-color: var(--color_black);
	cursor: pointer;
}
*/

@keyframes FadeIn { 
	0% {
	  opacity: 0;
	}
  
	100% {
	  opacity: 1;
	}

}

.bloc-content-newPresentationWorks-list .items a {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	z-index: 1;
	opacity: 0;
}

/*.bloc-content-newPresentationWorks-list .items a {
	background-color: red;
	animation: FadeIn 1s ease-in;
}*/

/* 
https://stackoverflow.com/questions/20490704/combine-calc-with-attr-in-css 
https://stackoverflow.com/questions/28989708/is-it-possible-to-use-the-nth-child-value-as-a-parameter-in-a-property-and-how/51601269#51601269
*/

.bloc-content-newPresentationWorks-list .items a {
	background-color: white;
	animation: FadeIn 1s;
	animation-delay: calc(var(--indexPreview) * .75s);
	animation-fill-mode: forwards;
	cursor: pointer;
}

.bloc-content-newPresentationWorks-list .preview img {
	object-fit: cover;
	/*width: 70%;*/
}

/*
.bloc-content-works-list .items:hover a {
	color: var(--color_white);
}

.bloc-content-works-list .items:hover svg .fill {
	fill: var(--color_white);
}
*/

