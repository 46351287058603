/* ================================= */
/* === MENU-MOBILE ================= */
/* ================================= */


.menu-mobile a:hover {
	text-decoration: none!important;
}

.menu-mobile {
	background-color: var(--color_white);
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 98;
	opacity: 0;
	visibility: hidden;
}

body.studio .menu-mobile {
	background-color: var(--color_black);
}

body.contact .menu-mobile {
	background-color: var(--color_yellow);
}

.menu-mobile.show {
	opacity: 1;
	visibility: visible;
}

@media only screen and (min-width: 768px) {
	.menu-mobile.show {
		opacity: 0;
		visibility: hidden;
	}
}

.menu-mobile .menu-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	text-transform: uppercase;
}

.menu-mobile .menu-container .logo {
	min-height: 101px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: var(--border_size) var(--border_type) var(--border_color);
}

body.studio .menu-mobile .logo {
	border-color: var(--color_white);
}

.menu-mobile .menu-container .logo svg {
	width: 30px;
	height: 43px;
}

.menu-mobile .menu-container .logo svg .fill {
	fill: var(--color_black);
}

body.studio .menu-mobile .logo svg .fill {
	fill: var(--color_white);
}

.menu-mobile .menu-container a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	margin: auto;
	border-bottom: var(--border_size) var(--border_type) var(--border_color);
}

body.studio .menu-mobile .menu-container a {
	border-color: var(--color_white);
}

.menu-mobile .menu-container .close {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	margin: auto;
	border-bottom: var(--border_size) var(--border_type) var(--color_white);
}

body.studio .menu-mobile .menu-container .close {
	border-color: var(--color_black);
}

body.contact .menu-mobile .menu-container .close {
	border-color: var(--color_yellow);
}

.menu-mobile .menu-container .close svg {
	width: 16px;
	height: 16px;
	cursor: pointer;
	transform: rotate(-45deg);
}

.menu-mobile .menu-container .close svg .fill {
	fill: var(--color_black);
}

body.studio .menu-mobile .menu-container .close svg .fill {
	fill: var(--color_white);
}