/* ================================= */
/* === MENU-DESKTOP ================ */
/* ================================= */

.menu-desktop {
	display: flex;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: 24px;
	transition: visibility 0s var(--time_1), opacity var(--time_1);
	opacity: 0;
	visibility: hidden;
}

.menu-desktop.show {
	opacity: 0;
	visibility: none;
}

.menu-desktop .menu-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 24px;
	width: 100%;
	border-top: var(--border_size) var(--border_type) var(--border_color);
	border-bottom: var(--border_size) var(--border_type) var(--border_color);
	transition: border var(--time_2);
	text-transform: uppercase;
}
 
body.studio .menu-desktop .menu-container {
	border-color: var(--color_white);
}

.menu-desktop .menu-container .facebookMenu {
	min-width: 24px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: var(--border_size) var(--border_type) var(--border_color);
	cursor: pointer;
	transition: border var(--time_2);
}

body.studio .menu-desktop .menu-container .facebookMenu {
	border-color: var(--color_white);
}

.menu-desktop .menu-container .facebookMenu svg {
	width: 16px;
	height: 16px;
	/*transition: fill var(--time_3);*/
}

.menu-desktop .menu-container .facebookMenu svg .fill {
	fill: var(--color_black);
}

.menu-desktop .menu-container .facebookMenu a:hover svg .fill {
	fill: #FFFFFF;
}

.studio .menu-desktop .menu-container .facebookMenu a:hover svg .fill {
	fill: #000000;
}

.contact .menu-desktop .menu-container .facebookMenu a:hover svg .fill {
	fill: var(--color_yellow);
}

body.studio .menu-desktop .menu-container .facebookMenu svg .fill {
	fill: var(--color_white);
}

.menu-desktop .menu-container a {
	text-align: center;
	width: 100%;
	height: 24px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: border var(--time_1);
}

.menu-desktop .menu-container a:hover {
	background: #000000;
	color: #FFFFFF;
}

.studio .menu-desktop .menu-container a:hover {
	background: #FFFFFF;
	color: #000000;
}

.contact .menu-desktop .menu-container a:hover {
	color: var(--color_yellow);
}

.menu-desktop .menu-container a:first-of-type {
	border-right: var(--border_size) var(--border_type) var(--border_color);
}

.menu-desktop .menu-container .facebookMenu a {
	border-right: 0;
}

body.studio .menu-desktop .menu-container a:first-of-type {
	border-color: var(--color_white);
	transition: border-color var(--time_1);
}

body.studio .menu-desktop .menu-container a:first-of-type {
	border-color: var(--color_white);
}

.menu-desktop .menu-container a:last-of-type {
	border-left: var(--border_size) var(--border_type) var(--border_color);
}

.menu-desktop .menu-container .facebookMenu a {
	border-left: 0;
}

body.studio .menu-desktop .menu-container a:last-of-type {
	border-color: var(--color_white);
}

.menu-desktop .menu-container .heart {
	min-width: 24px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-left: var(--border_size) var(--border_type) var(--border_color);
	/*transition: border var(--time_2);*/
}

body.studio .menu-desktop .menu-container .heart {
	border-color: var(--color_white);
}

.menu-desktop .menu-container .heart a {
	border: none;
	text-align: unset;
	font-weight: unset;
}

.menu-desktop .menu-container .heart svg {
	width: 16px;
	height: 16px;
}

.menu-desktop .menu-container .heart svg .fill {
	fill: var(--color_black);
	/*transition: fill var(--time_2);*/
}

.menu-desktop .menu-container .heart a:hover svg .fill {
	fill: #FFFFFF;
}

.studio .menu-desktop .menu-container .heart a:hover svg .fill {
	fill: #000000;
}

.contact .menu-desktop .menu-container .heart a:hover svg .fill {
	fill: var(--color_yellow);
}

body.studio .menu-desktop .menu-container .heart svg .fill {
	fill: var(--color_white);
}

@media only screen and (min-width: 768px) {
	.menu-desktop.show {
		opacity: 1;
		visibility: visible;
		transition: opacity var(--time_1); 
	}
}