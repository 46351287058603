/* ================================= */
/* === WORKSMOBILEHOME ============= */
/* ================================= */

/* QUAND ON EST SUR MOBILE, AVEC LE PORTFOLIO D'OUVERT (HOME - TAP FOR PLEASURE) ON SUPPRIME LES BARRES DE SCROLL */
body.portfolioMobile .content {
	overflow: hidden;
}

body.portfolioMobile.contact .content {
	overflow-y: scroll;
}

@media only screen and (min-width: 768px) {
	body.home .content {
		overflow: hidden; 
	}
}

body.studio .content {
	overflow-y: scroll;
}

@media only screen and (min-width: 768px) {
	body.studio .content {
		overflow-y: hidden;
	}
}

body.portfolioMobile .bloc-worksMobile {
	grid-template-columns: 1fr;
	overflow: hidden;
}

body.portfolioMobile .bloc-content-worksMobile {
	background-color: var(--color_white);
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

body.portfolioMobile .bloc-content-worksMobile .image,
body.portfolioMobile .bloc-content-worksMobile .image img {
	height: 100%;
	width: auto;
	background-color: var(--color_white);
	display: flex;
	justify-content: center;
	align-items: center;
}
