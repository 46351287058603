/* ================================= */
/* === FOOTER ====================== */
/* ================================= */

.footer a:hover {
	text-decoration: none!important;
}

.footer {
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	position: relative;
}

@media only screen and (max-width: 300px), screen and (max-height: 400px) {
	.footer {
		display: none!important;
		visibility: hidden!important;
	}
}

@media only screen and (min-width: 768px) {
	.footer {
		min-height: 125px;
		flex-basis: 125px;
		flex-grow: 1;
	}
	
	.footer .openMenuMobile {
		display: none;
		visibility: hidden;
	}
}

.footer .openMenu,
.footer .openMenuMobile {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	height: 24px;
	width: 24px;
}

/* Quand on est en Mobile (.openMenuMobile), on n'affiche pas le menu Desktop (.openMenu) */
@media only screen and (max-width: 768px) {
	.footer .openMenu {
		display: none;
		visibility: hidden;
	}
}

.footer .openMenu svg,
.footer .openMenuMobile svg {
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.footer .openMenu svg .fill,
.footer .openMenuMobile svg .fill {
	fill: var(--color_black);
	transition: fill var(--time_3);
}

body.studio .footer .openMenu svg .fill,
body.studio .footer .openMenuMobile svg .fill {
	fill: var(--color_white);
}

.footer .openMenu svg,
.footer .openMenuMobile svg {
	transition: transform var(--time_2);
}

@media only screen and (min-width: 768px) {
	.footer .openMenu.opened svg,
	.footer .openMenuMobile.opened svg {
		transform: rotate(-45deg);
	}
}

@media only screen and (max-width: 300px), screen and (max-height: 400px) {
	.footer .openMenu,
	.footer .openMenu.opened {
		display: none;
		visibility: hidden;
	}
}

.footer .credits {
	position: absolute;
	bottom: 0;
	font-size: .6rem;
}