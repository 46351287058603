/* ================================= */
/* === IMPRESSUM =================== */
/* ================================= */

body.impressum .content {
	grid-template-columns: 100%;
}

@media only screen and (min-width: 768px) {
	body.impressum .content {
		grid-template-columns: 50% 50%;
	}
	
	body.impressum .bloc-content:nth-child(2),
	body.impressum .bloc-content:nth-child(3) {
		border-left: var(--border_size) var(--border_type) var(--border_color);
	}
	
}