/* ================================= */
/* === ERROR ======================= */
/* ================================= */

body.error .content {
	grid-template-columns: 1fr;
	overflow: scroll;
}

body.error .bloc-cross:nth-child(2){
	display: none;
	visibility: hidden;
}

body.error .bloc-video {
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

body.error .bloc-video video {
	height: 70%;
}

@media only screen and (min-width: 768px) {
	body.error .content {
		grid-template-columns: repeat(2, 1fr);
		overflow: hidden;
	}
	
	body.error .bloc-cross:nth-child(2){
		display: block;
		visibility: visible;
	}
}