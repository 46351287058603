/* ================================= */
/* === HEADER ====================== */
/* ================================= */

.header {
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 300px), screen and (max-height: 400px) {
	.header {
		display: none!important;
		visibility: hidden!important;
	}
}

@media only screen and (min-width: 768px) {
	.header {
		min-height: 125px;
		flex-basis: 125px;
		flex-grow: 1;
	}
}

.header svg {
	width: 30px;
	height: 43px;
	cursor: pointer;
}

.header svg .fill {
	fill: var(--color_black);
	transition: fill 2s;
}

body.studio .header svg .fill {
	fill: var(--color_white);
}