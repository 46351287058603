/* ================================= */
/* === CONTACT ===================== */
/* ================================= */

body.contact {
	background-color: var(--color_yellow);
}

body.contact .information {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 65px);
}

body.contact .contact_informations {
	margin: auto 0;
}

body.contact .list-items {
	display: flex;
}

body.contact .list-items li {
	width: 16px;
	height: 16px;
	margin-right: 24px;
}


body.contact #more-network.item.hide,
body.contact .list-items li.item.item-hide {
	display: none;
	visibility: hidden;
}

body.contact .list-items li#more-network:hover {
	cursor: pointer;
}

body.contact .bloc-content:nth-child(2) {
	height: 100%;
}

body.contact .form {
	height: calc(100% - 65px);
}

body.contact .fields,
body.contact .content-form {
	height: 100%;
}

body.contact form {
	display: flex;
	flex-direction: column;
	padding: 1em;
	height: 100%;
}

body.contact .form-input {
	display: flex;
}

body.contact .form-field,
body.contact .form-textarea {
	display: flex;
	flex-direction: column;
	height: 100%;
}

body.contact .form-field {
	width: 50%;
}

body.contact .form-field:first-of-type {
	margin-right: 4px;
}

body.contact .form-field:last-of-type {
	margin-left: 4px;
}

body.contact label {
	font-weight: 700;
	text-align: left;
	width: 100%;
	margin-bottom: 1em;
}

body.contact input[type="text"],
body.contact input[type="submit"],
body.contact textarea {
-webkit-appearance: none;
   -moz-appearance: none;
        appearance: none;
		border-radius: 0;
}

body.contact input[type="submit"] {
	text-transform: uppercase;
}

body.contact input:-webkit-autofill,
body.contact input:-webkit-autofill:hover, 
body.contact input:-webkit-autofill:focus,
body.contact textarea:-webkit-autofill,
body.contact textarea:-webkit-autofill:hover,
body.contact textarea:-webkit-autofill:focus {
	-webkit-text-fill-color: var(--color_black);
	-webkit-box-shadow: 0 0 0px 1000px var(--color_yellow) inset;
	transition: background-color 5000s ease-in-out 0s;
}

body.contact input[type="text"] {
	background-color: transparent;
	height: 32px;
	border: var(--border_size) var(--border_type) var(--border_color);
}

body.contact body.contact .form-textarea,
body.contact textarea {
	height: 100%;
}

body.contact .form-textarea label {
	margin-top: 1em;
}

body.contact textarea {
	font-family: var(--font_family);
	font-weight: 300;
	font-style: var(--font_style);
	font-size: var(--font_size);
	background-color: transparent;
	border: var(--border_size) var(--border_type) var(--border_color);
	resize: none;
}

body.contact .form-submit {
	display: flex;
	justify-content: center;
	margin: 1em 0 0 0;
}

body.contact input[type="submit"] {
	height: 24px;
	width: 200px;
	border: var(--border_size) var(--border_type) var(--border_color);
	background-color: transparent;
	color: var(--color_black);
	font-weight: 700;
	font-size: 1rem;
}

body.contact input[type="submit"]:hover {
	cursor: pointer !important;
	background-color: var(--color_black);
	color: var(--color_yellow);
}

body.contact .form .error {
	color: #FF0000;
	padding: 4px 0;
}

body.contact .bloc-shop,
body.contact .bloc-more {
	border-color: var(--color_white);
}

@media only screen and (min-width: 768px) {
	body.contact .content {
		grid-template-columns: 50% 50%;
	}
	
	body.contact .bloc-content .title  {
		border-color: var(--color_black);
	}
	
	body.contact .bloc-content:nth-child(2),
	body.contact .bloc-content:nth-child(3) {
		border-left: var(--border_size) var(--border_type) var(--border_color);
	}
	
	body.contact .bloc-shop,
	body.contact .bloc-more,
	body.contact .bloc-shop,
	body.contact .bloc-more {
		border-left: var(--border_size) var(--border_type) var(--border_color);
	}
	
	body.contact .bloc-content-shop {
		background-color: var(--color_yellow);
	}	
}

body.contact .bloc-cross .title {
	background-color: var(--color_yellow);
	border-color: var(--color_white);
}